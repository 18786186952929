import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import { useAuthStore } from './store/auth';
import './assets/style.css'

const pinia = createPinia()

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(store)

const authStore = useAuthStore();

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      authStore.logout(); 
      router.push('/login'); 
    }
    return Promise.reject(error); 
  }
);
setInterval(() => {
  const token = authStore.token;
  if (token) {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (Date.now() >= exp * 1000) {
      authStore.logout();
      router.push({ name: 'Login' });
    }
  }
}, 60000); // Check every minute

app.mount('#app');
