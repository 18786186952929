<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <h1 class="text-sm font-bold ">Liste des épreuves</h1>
                        <div class="flex items-center">
                            <div class="w-[300px] md:max-w-[300px] max-w-[180px] mr-[15px] relative">
                                <label for="" class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700"><i class="fa-solid fa-search"></i></label>
                                <input type="text" v-model="searchQuery" placeholder="Recherchez une épreuve" class="w-full p-[10px] pr-[30px] bg-transparent border border-gray-300 rounded-[5px] text-sm outline-none">
                            </div>
                            <div @click="toggleFiltre()" class="w-[35px] h-[35px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-gray-600">
                                <i class="fa-solid fa-filter text-sm font-bold"></i>
                            </div>
                        </div>
                    </div>
                    <div v-if="filtre" class="w-full relative flex items-center my-[10px]">
                        <div class="w-full relative">
                            <select v-model="selectedMatiere" class="w-full p-[10px] rounded-[5px] text-sm outline-none border border-gray-300 bg-transparent text-gray-600">
                                <option value="">Toutes les matières</option>
                                <option v-for="matiere in matieresUniques" :key="matiere" :value="matiere">{{ matiere }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class=" w-full">
                    <router-link v-for="(epreuve, index) in filteredEpreuves" :key="index" :class="['w-full md:px-[20px] px-[10px] cursor-pointer my-[5px] py-[10px] rounded-[5px] bg-white flex justify-between items-center', { 'cursor-not-allowed opacity-50': !epreuve.isActive }]" :style="!epreuve.isActive ? 'filter: blur(3px); pointer-events: none;' : ''" :to="epreuve.isActive ? '/epreuves/'+ epreuve._id : '#'">
                        <div class="flex items-center md:w-auto w-[calc(100%-80px)]">
                            <div class="w-[35px] h-[40px] min-w-[35px] min-h-[40px] relative overflow-hidden flex justify-center items-center">
                                <img src="../assets/images/epreuve.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <div class="flex flex-col ml-[10px]">
                                <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                                <p class="text-[10px] clamp font-semibold text-gray-500">
                                    {{ epreuve.titre }}
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="p-[10px] rounded-[5px] bg-gray-100 flex text-[10px] font-bold text-gray-600">
                                <div class="hidden md:flex items-center">
                                    <i class="fa-solid fa-calendar pr-[5px]"></i>
                                    <span>{{ epreuve.createdAt }}</span>
                                </div>
                                <span class="mx-[5px] hidden md:flex">|</span>
                                <div class="flex items-center">
                                    <i class="fa-solid fa-star pr-[5px]"></i>
                                    <span>5</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-arrow-right ml-[10px] text-sm"></i>
                        </div>
                    </router-link>
                    <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                        <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                        :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                        class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                            {{ page }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && filteredEpreuves.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucune épreuve trouvée.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner"></div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, onMounted, computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const isLoading = ref(false)
const filtre = ref(true)
const epreuves = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const searchQuery = ref('')
const selectedMatiere = ref('')
const selectedYear = ref(null)

function toggleFiltre() {
    filtre.value = !filtre.value
}

// Fonction pour extraire les matières uniques
const matieresUniques = computed(() => {
    const allMatieres = epreuves.value.map(epreuve => epreuve.matiere);
    return [...new Set(allMatieres)]; // Pour obtenir des matières uniques
});

function applyYearFilter(year) {
    selectedYear.value = year;
}

function yearButtonClass(year) {
    return selectedYear.value === year ? 'bg-blue-950 text-white border border-blue-950' : 'bg-gray-200';
}

const fetchEpreuves = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/epreuves')
        const allEpreuves = response.data;

        const filteredEpreuves = allEpreuves.filter(epr => {
            return epr.filiere === user.value.filiere && epr.year === user.value.year;
        });

        epreuves.value = filteredEpreuves;
    } catch {
        errorMessage.value = "Erreur lors du chargement des épreuves"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const filteredEpreuves = computed(() => {
    return epreuves.value.filter(epreuve => {
        const matchesMatiere = selectedMatiere.value ? epreuve.matiere.includes(selectedMatiere.value) : true;
        const matchesYear = selectedYear.value ? epreuve.year === selectedYear.value : true;
        const matchesSearch = epreuve.titre.toLowerCase().includes(searchQuery.value.toLowerCase());
        return matchesMatiere && matchesYear && matchesSearch;
    });
});

const currentPage = ref(1);
const itemsPerPage = ref(20);
const totalPages = computed(() => Math.ceil(filteredEpreuves.value.length / itemsPerPage.value));

function goToPage(page) {
    currentPage.value = page;
}

function hideErrorMessageAfterDelay() {
    setTimeout(() => errorMessage.value = '', 5000)
}

onMounted(() => {
    fetchEpreuves()
})
</script>
